<template>
  <div class="grievance-wrapper">
    <div class="mb-32 component__header">
      <h1>नागरिक प्रतिक्रिया</h1>
    </div>
    <GrievanceComponents />
  </div>
</template>

<script>
import GrievanceComponents from "./GrievanceComponents.vue";
export default {
  components: {
    GrievanceComponents,
  },
};
</script>
